import {Home} from "./components/Home";

const AppRoutes = [
    {
        index: true,
        element: <Home/>
    },
    // {
    //     path: '/counter',
    //     element: <Counter/>
    // },
    // {
    //     path: '/fetch-data',
    //     element: <FetchData/>
    // }
];

export default AppRoutes;
