import React, { Component } from 'react';
import {
    Card, CardContent, CardHeader, Grid, TextField, Autocomplete, InputLabel, Select, MenuItem
} from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

//import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
//import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

let apis = {
    API_GETVIDEOLINKS: '/api/MatchMaking/GetVideoLinksForSportsman',
    API_GETALLSPORTSMAN: '/api/MatchMaking/GetAllSportsman',
    API_GETALLEVENTS: '/api/MatchMaking/GetAllEvents',
    API_GETBOUTSFORANEVENT: '/api/MatchMaking/GetBoutsForAnEvent',
    API_GETALLEVENTSFROMMMAFED: '/api/MatchMaking/GetAllEventsFromMMAFed',
    API_GETEVENTWITHBOUTSFROMMMAFED: '/api/MatchMaking/GetEventWithBoutsFromMMAFed',

    API_ADDNEWSPORTSMAN: '/api/MatchMaking/AddNewSportsman',
    API_UPDATESPORTSMAN: '/api/MatchMaking/UpdateSportsman',
    API_ADDNEWSPORTSMANVIDEOLINK: '/api/MatchMaking/AddNewSportsmanVideoLink',
    API_REMOVESPORTSMANVIDEOLINK: '/api/MatchMaking/RemoveSportsmanVideoLink',
    API_ADDNEWEVENT: '/api/MatchMaking/AddNewEvent',
    API_UPDATEEVENT: '/api/MatchMaking/UpdateEvent',
    API_ADDNEWBOUTMATCH: '/api/MatchMaking/AddNewBoutMatch',
    API_UPDATEBOUTMATCH: '/api/MatchMaking/UpdateBoutMatch',

    API_SPREADSHEETMODIFY: '/api/MatchMaking/SpreadsheetModify',
}

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true, isNotAuthorized: true,

            sportsman_id: 0, sportsman_name: "", sportsman_surname: "",

            event_name: "", event_date: "",

            event_name_mmafed: "", event_date_mmafed: "",

            weight_list_male: [{
                label: "Strawweight 115 lb (52.2 kg) M", id: 522, weight: 52.2
            }, { label: "Flyweight 125 lb (56.7 kg) M", id: 567, weight: 56.7 }, {
                label: "Bantamweight 135 lb (61.2 kg) M", id: 612, weight: 61.2
            }, { label: "Featherweight 145 lb (65.8 kg) M", id: 658, weight: 65.8 }, {
                label: "Lightweight 155 lb (70.3 kg) M", id: 703, weight: 70.3
            }, { label: "Super lightweight 165 lb (74.8 kg) M", id: 748, weight: 74.8 }, {
                label: "Welterweight 170 lb (77.1 kg) M", id: 771, weight: 77.1
            }, { label: "Super welterweight 175 lb (79.4 kg) M", id: 794, weight: 79.4 }, {
                label: "Middleweight 185 lb (83.9 kg) M", id: 839, weight: 83.9
            }, {
                label: "Super middleweight 195 lb (88.5 kg) M", id: 885, weight: 88.5
            }, { label: "Light heavyweight 205 lb (93.0 kg) M", id: 930, weight: 93.0 }, {
                label: "Cruiserweight 225 lb (102.1 kg) M", id: 1021, weight: 102.1
            }, { label: "Heavyweight 265 lb (120.2 kg) M", id: 1202, weight: 120.2 }, {
                label: "Super heavyweight No limit M", id: 10000, weight: 10000
            },], weight_list_female: [{
                label: "Strawweight 115 lb (52.2 kg) F", id: 522, weight: 52.2
            }, { label: "Flyweight 125 lb (56.7 kg) F", id: 567, weight: 56.7 }, {
                label: "Bantamweight 135 lb (61.2 kg) F", id: 612, weight: 61.2
            }, { label: "Featherweight 145 lb (65.8 kg) F", id: 658, weight: 65.8 }, {
                label: "Lightweight 155 lb (70.3 kg) F", id: 703, weight: 70.3
            }, { label: "Super lightweight 165 lb (74.8 kg) F", id: 748, weight: 74.8 }, {
                label: "Welterweight 170 lb (77.1 kg) F", id: 771, weight: 77.1
            }, { label: "Super welterweight 175 lb (79.4 kg) F", id: 794, weight: 79.4 }, {
                label: "Middleweight 185 lb (83.9 kg) F", id: 839, weight: 83.9
            }, {
                label: "Super middleweight 195 lb (88.5 kg) F", id: 885, weight: 88.5
            }, { label: "Light heavyweight 205 lb (93.0 kg) F", id: 930, weight: 93.0 }, {
                label: "Cruiserweight 225 lb (102.1 kg) F", id: 1021, weight: 102.1
            }, { label: "Heavyweight 265 lb (120.2 kg) F", id: 1202, weight: 120.2 }, {
                label: "Super heavyweight No limit F", id: 10000, weight: 10000
            },], sportsman_weight: null, // sportsman_weight_input: 0,
            sportsman_sex: 1, sportsman_sherdog_link: "", sportsman_tapology_link: "", sportsman_video_links: [],

            list_all_sportsman: [], list_all_events: [],

            list_all_events_mmafed: [], event_with_bouts_mmafed: [],

            selected_sportsman: null, selected_event: null, selected_event_mmafed: null,

            get_bouts_for_an_event: [], selected_bout: null,

            video_link_address: "", // video_link_id: -1,

            sportsman_left: null, sportsman_right: null,

            bout_kg: "", matchmaking_status: 0,

            event_id_mmafed: 0,
        };
    }

    componentDidMount() {
        this.getAllSportsman();
        this.getAllEvents();
        this.getAllEventsFromMMAFed();
    }

    resetSportsmanValues = () => {
        this.setState({
            sportsman_id: 0,
            sportsman_name: "",
            sportsman_surname: "",
            sportsman_weight: null,
            sportsman_sex: 1,
            sportsman_sherdog_link: "",
            sportsman_tapology_link: "",
            sportsman_video_links: [],
            selected_sportsman: null,
        });
    }

    handleChange = (e) => {
        // console.log("handleChange " + e.target.name + " " + e.target.value);
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleWeightChange = (e, v) => {
        if (v !== undefined && v !== null) this.setState({ sportsman_weight: v });
    }

    handleEventChange = async (e, v) => {
        if (v !== undefined && v !== null) await this.setState({ selected_event: v });

        await this.setState({
            event_name: v.EventName, event_date: v.EventDate
        });

        await this.getBoutsForAnEvent();
    }

    handleEventMMAFedChange = async (e, v) => {
        if (v !== undefined && v !== null) await this.setState({
            selected_event_mmafed: v, event_id_mmafed: v.id
        });

        await this.setState({
            event_name_mmafed: v.baslik, event_date_mmafed: v.tarih
        });

        await this.getEventWithBoutsFromMMAFed();
    }

    // handleWeightInputChange = (e, v) => {
    //     if (v !== undefined && v !== null) this.setState({sportsman_weight_input: v.props.value});
    // }

    handleSexChange = (e, v) => {
        if (v !== undefined && v !== null) this.setState({
            sportsman_sex: v.props.value, sportsman_weight: null
        });
    }

    handleMatchMakingStatusChange = (e, v) => {
        if (v !== undefined && v !== null) this.setState({
            matchmaking_status: v.props.value
        });
    }

    handleSportsmanChange = async (e, v) => {
        if (v !== undefined && v !== null) await this.setState({
            selected_sportsman: v
        });

        // console.log("weight: ", this.state.selected_sportsman.SportsmanWeight, " ",
        //     " ** ", this.state.weight_list_male.filter((element) => element.weight === this.state.selected_sportsman.SportsmanWeight));

        let weight_object = this.state.selected_sportsman.SportsmanSex ? this.state.weight_list_male.filter((element) => element.weight === this.state.selected_sportsman.SportsmanWeight) : this.state.weight_list_female.filter((element) => element.weight === this.state.selected_sportsman.SportsmanWeight);

        // console.log("weight_object: ", weight_object[0]);

        await this.setState({
            sportsman_id: this.state.selected_sportsman.idSportsman,
            sportsman_name: this.state.selected_sportsman.SportsmanName,
            sportsman_surname: this.state.selected_sportsman.SportsmanSurname,
            sportsman_weight: weight_object[0],
            sportsman_sex: this.state.selected_sportsman.SportsmanSex,
            sportsman_sherdog_link: this.state.selected_sportsman.SportsmanSherdogLink,
            sportsman_tapology_link: this.state.selected_sportsman.SportsmanTapologyLink, // sportsman_video_links: [],
        });

        await this.getVideoLink();
    }

    handleSportsmanLeftChange = async (e, v) => {
        if (v !== undefined && v !== null) await this.setState({
            sportsman_left: v
        });
    }

    handleSportsmanRightChange = async (e, v) => {
        if (v !== undefined && v !== null) await this.setState({
            sportsman_right: v
        });
    }

    handleBoutChange = (e, v) => {

        // console.log("*** v: ", v);

        if (v !== undefined && v !== null) this.setState({
            selected_bout: v,

            sportsman_left: this.state.list_all_sportsman.filter((element) => element.idSportsman === v.SportsmanIdLeft)[0],
            sportsman_right: this.state.list_all_sportsman.filter((element) => element.idSportsman === v.SportsmanIdRight)[0],
            bout_kg: v.BoutKilo,
            matchmaking_status: v.MatchingStatus,
        });
    }

    addNewSportsman = async () => {
        console.log("addNewSportsman");

        await fetch(apis.API_ADDNEWSPORTSMAN, {
            method: "POST", headers: {
                Authorization: "Bearer " + localStorage.getItem("authtoken"),
                Accept: "application/json",
                "Content-Type": "application/json"
            }, body: JSON.stringify(this.state)
        })
            .then(response => response.json())
            .then(result => {
                console.log(result);

                if (result.resultstate === 0) {
                    this.resetSportsmanValues();
                    this.getAllSportsman();
                }
            });
    }

    updateSportsman = async () => {
        console.log("updateSportsman");

        // await this.setState({selected_sportsman: null});

        await fetch(apis.API_UPDATESPORTSMAN, {
            method: "POST", headers: {
                Authorization: "Bearer " + localStorage.getItem("authtoken"),
                Accept: "application/json",
                "Content-Type": "application/json"
            }, body: JSON.stringify(this.state)
        })
            .then(response => response.json())
            .then(result => {
                console.log(result);

                if (result.resultstate === 0 && result.update_sportsman_result === true) {
                    this.resetSportsmanValues();
                    this.getAllSportsman();
                }
            });

        // let s = this.state.list_all_sportsman.filter(
        //     (element) => element.idSportsman === this.state.sportsman_id)[0];
        // console.log("s: ", s);
        // await this.setState({
        //     selected_sportsman: s
        // });
    }

    addNewEvent = async () => {
        console.log("addNewEvent");

        await fetch(apis.API_ADDNEWEVENT, {
            method: "POST", headers: {
                Authorization: "Bearer " + localStorage.getItem("authtoken"),
                Accept: "application/json",
                "Content-Type": "application/json"
            }, body: JSON.stringify(this.state)
        })
            .then(response => response.json())
            .then(result => {
                console.log(result);

                if (result.resultstate === 0) {

                    this.setState({
                        selected_event: null, event_name: "", event_date: ""
                    });

                    this.getAllEvents();
                }
            });
    }

    updateEvent = async () => {
        console.log("updateEvent");

        await fetch(apis.API_UPDATEEVENT, {
            method: "POST", headers: {
                Authorization: "Bearer " + localStorage.getItem("authtoken"),
                Accept: "application/json",
                "Content-Type": "application/json"
            }, body: JSON.stringify(this.state)
        })
            .then(response => response.json())
            .then(result => {
                console.log(result);

                if (result.resultstate === 0 && result.update_event_result === true) {

                    this.setState({
                        selected_event: null, event_name: "", event_date: ""
                    });

                    this.getAllEvents();
                }
            });
    }

    addNewSportsmanVideoLink = async () => {
        console.log("addNewSportsmanVideoLink");

        await fetch(apis.API_ADDNEWSPORTSMANVIDEOLINK, {
            method: "POST", headers: {
                Authorization: "Bearer " + localStorage.getItem("authtoken"),
                Accept: "application/json",
                "Content-Type": "application/json"
            }, body: JSON.stringify(this.state)
        })
            .then(response => response.json())
            .then(result => {
                console.log(result);

                if (result.resultstate === 0) {
                    this.getVideoLink();

                    this.setState({
                        video_link_address: ""
                    });
                }
            });
    }

    removeSportsmanVideoLink = async (id) => {
        console.log("removeSportsmanVideoLink ", id);

        await fetch(apis.API_REMOVESPORTSMANVIDEOLINK, {
            method: "POST", headers: {
                Authorization: "Bearer " + localStorage.getItem("authtoken"),
                Accept: "application/json",
                "Content-Type": "application/json"
            }, body: JSON.stringify({ video_link_id: id })
        })
            .then(response => response.json())
            .then(result => {
                console.log(result);

                if (result.resultstate === 0) {
                    this.getVideoLink();
                }
            });
    }

    addNewBoutMatch = async () => {
        console.log("addNewBoutMatch");

        await fetch(apis.API_ADDNEWBOUTMATCH, {
            method: "POST", headers: {
                Authorization: "Bearer " + localStorage.getItem("authtoken"),
                Accept: "application/json",
                "Content-Type": "application/json"
            }, body: JSON.stringify(this.state)
        })
            .then(response => response.json())
            .then(result => {
                console.log(result);

                if (result.resultstate === 0) {
                    this.getBoutsForAnEvent();

                    this.setState({
                        selected_bout: null,
                        sportsman_left: null,
                        sportsman_right: null,
                        bout_kg: "",
                        matchmaking_status: 0,
                    });
                }
            });
    }

    updateBoutMatch = async () => {
        console.log("updateBoutMatch");

        await fetch(apis.API_UPDATEBOUTMATCH, {
            method: "POST", headers: {
                Authorization: "Bearer " + localStorage.getItem("authtoken"),
                Accept: "application/json",
                "Content-Type": "application/json"
            }, body: JSON.stringify(this.state)
        })
            .then(response => response.json())
            .then(result => {
                console.log(result);

                if (result.resultstate === 0 && result.update_bout_match_result === true) {
                    this.getBoutsForAnEvent();

                    this.setState({
                        selected_bout: null,
                        sportsman_left: null,
                        sportsman_right: null,
                        bout_kg: "",
                        matchmaking_status: 0,
                    });
                }
            });
    }

    selectBout = async (id) => {
        console.log("selectBout ", id);

        if (id !== undefined && id !== null) {
            let the_bout = this.state.get_bouts_for_an_event.filter((element => element.idMatchMaking === id))[0];

            await this.setState({ selected_bout: the_bout });

            await this.setState({
                sportsman_left: this.state.list_all_sportsman.filter((element) => element.idSportsman === the_bout.SportsmanIdLeft)[0],
                sportsman_right: this.state.list_all_sportsman.filter((element) => element.idSportsman === the_bout.SportsmanIdRight)[0],
                bout_kg: the_bout.BoutKilo,
                matchmaking_status: the_bout.MatchingStatus,
            });
        }
    }

    getAllSportsman = async () => {

        await fetch(apis.API_GETALLSPORTSMAN, {
            method: "POST", headers: {
                Authorization: "Bearer " + localStorage.getItem("authtoken"),
                Accept: "application/json",
                "Content-Type": "application/json"
            }, body: JSON.stringify(this.state)
        })
            .then(response => response.json())
            .then(result => {
                console.log(result);

                if (result.resultstate === 2) {
                    this.setState({
                        isLoading: false, isNotAuthorized: true
                    });
                } else {
                    this.setState({
                        list_all_sportsman: result.list_all_sportsman, isLoading: false, isNotAuthorized: false
                    });
                }
            });
    };

    getAllEvents = async () => {

        await fetch(apis.API_GETALLEVENTS, {
            method: "POST", headers: {
                Authorization: "Bearer " + localStorage.getItem("authtoken"),
                Accept: "application/json",
                "Content-Type": "application/json"
            }, body: JSON.stringify(this.state)
        })
            .then(response => response.json())
            .then(result => {
                console.log(result);

                if (result.resultstate === 2) {
                    this.setState({
                        isLoading: false, isNotAuthorized: true
                    });
                } else {
                    this.setState({
                        list_all_events: result.list_all_events, isLoading: false, isNotAuthorized: false
                    });
                }
            });
    };

    getAllEventsFromMMAFed = async () => {

        await fetch(apis.API_GETALLEVENTSFROMMMAFED, {
            method: "POST", headers: {
                Authorization: "Bearer " + localStorage.getItem("authtoken"),
                Accept: "application/json",
                "Content-Type": "application/json"
            }, body: JSON.stringify(this.state)
        })
            .then(response => response.json())
            .then(result => {
                console.log(result);

                if (result.resultstate === 2) {
                    this.setState({
                        isLoading: false, isNotAuthorized: true
                    });
                } else {
                    this.setState({
                        list_all_events_mmafed: result.list_all_events_mmafed, isLoading: false, isNotAuthorized: false
                    });
                }
            });
    };

    getEventWithBoutsFromMMAFed = async () => {

        await fetch(apis.API_GETEVENTWITHBOUTSFROMMMAFED, {
            method: "POST", headers: {
                Authorization: "Bearer " + localStorage.getItem("authtoken"),
                Accept: "application/json",
                "Content-Type": "application/json"
            }, body: JSON.stringify(this.state)
        })
            .then(response => response.json())
            .then(result => {
                console.log(result);

                if (result.resultstate === 2) {
                    this.setState({
                        isLoading: false, isNotAuthorized: true
                    });
                } else {
                    this.setState({
                        event_with_bouts_mmafed: result.event_with_bouts_mmafed,
                        isLoading: false,
                        isNotAuthorized: false
                    });
                }
            });
    };

    getBoutsForAnEvent = async () => {

        await this.setState({
            selected_bout: null
        });

        await fetch(apis.API_GETBOUTSFORANEVENT, {
            method: "POST", headers: {
                Authorization: "Bearer " + localStorage.getItem("authtoken"),
                Accept: "application/json",
                "Content-Type": "application/json"
            }, body: JSON.stringify(this.state)
        })
            .then(response => response.json())
            .then(result => {
                console.log(result);

                if (result.resultstate === 2) {
                    this.setState({
                        isLoading: false, isNotAuthorized: true
                    });
                } else {
                    this.setState({
                        get_bouts_for_an_event: result.get_bouts_for_an_event, isLoading: false, isNotAuthorized: false
                    });
                }
            });
    };

    getVideoLink = async () => {

        await fetch(apis.API_GETVIDEOLINKS, {
            method: "POST", headers: {
                Authorization: "Bearer " + localStorage.getItem("authtoken"),
                Accept: "application/json",
                "Content-Type": "application/json"
            }, body: JSON.stringify(this.state)
        })
            .then(response => response.json())
            .then(result => {
                console.log(result);

                if (result.resultstate === 2) {
                    this.setState({
                        isLoading: false, isNotAuthorized: true
                    });
                } else {
                    this.setState({
                        sportsman_video_links: result.video_links, isLoading: false, isNotAuthorized: false
                    });
                }
            });
    };

    generateSherdog = async () => {

        if (this.state.selected_event_mmafed !== null &&
            this.state.selected_event_mmafed !== undefined) {

            await fetch(apis.API_SPREADSHEETMODIFY, {
                method: "POST", headers: {
                    Authorization: "Bearer " + localStorage.getItem("authtoken"),
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }, body: JSON.stringify(this.state)
            })
                .then(response => response.json())
                .then(result => {
                    console.log(result);

                    if (result.resultstate === 0) {
                        window.location = "/api/MatchMaking/DownloadFilename/" + result.sherdog_details.sherdog_filename;
                    } else {

                    }
                });
        }
    };

    render() {

        // const rows = [
        //     {"id": 1, "name": 'Frozen yoghurt', "calories": 159, "fat": 6.0, "carbs": 24, "protein": 4.0},
        //     {"id": 2, "name": 'Ice cream sandwich', "calories": 237, "fat": 9.0, "carbs": 37, "protein": 4.3},
        //     {"id": 3, "name": 'Eclair', "calories": 262, "fat": 16.0, "carbs": 24, "protein": 6.0},
        //     {"id": 4, "name": 'Cupcake', "calories": 305, "fat": 3.7, "carbs": 67, "protein": 4.3},
        //     {"id": 5, "name": 'Gingerbread', "calories": 356, "fat": 16.0, "carbs": 49, "protein": 3.9},
        // ];

        const {
            sportsman_name,
            sportsman_surname,
            sportsman_weight, // sportsman_weight_input,
            sportsman_sex,
            event_name,
            event_date, // event_name_mmafed,
            // event_date_mmafed,
            weight_list_male,
            weight_list_female,
            sportsman_sherdog_link,
            sportsman_tapology_link,
            sportsman_video_links,

            list_all_sportsman,
            list_all_events,
            list_all_events_mmafed,
            event_with_bouts_mmafed,

            selected_sportsman,
            selected_event,
            selected_event_mmafed,

            get_bouts_for_an_event,
            selected_bout,

            video_link_address,

            sportsman_left,
            sportsman_right,

            bout_kg,
            matchmaking_status,

            // event_id_mmafed,
        } = this.state;

        return (<div>
            {/*<Card>*/}
            {/*    <CardHeader title="Add New Sportsman" className="card-header"></CardHeader>*/}
            {/*    <CardContent>*/}
            {/*        <Grid container spacing={2}>*/}
            {/*            <Grid item xs={4}>*/}
            {/*                <TextField*/}
            {/*                    label="Name: "*/}
            {/*                    placeholder=""*/}
            {/*                    margin="normal" fullWidth*/}
            {/*                    InputLabelProps={{*/}
            {/*                        shrink: true,*/}
            {/*                    }}*/}
            {/*                    value={sportsman_name}*/}
            {/*                    onChange={this.handleChange.bind(this)}*/}
            {/*                    name="sportsman_name"*/}
            {/*                    type="text"*/}
            {/*                />*/}
            {/*                <TextField*/}
            {/*                    label="Surname: "*/}
            {/*                    placeholder=""*/}
            {/*                    margin="normal" fullWidth*/}
            {/*                    InputLabelProps={{*/}
            {/*                        shrink: true,*/}
            {/*                    }}*/}
            {/*                    value={sportsman_surname}*/}
            {/*                    onChange={this.handleChange.bind(this)}*/}
            {/*                    name="sportsman_surname"*/}
            {/*                    type="text"*/}
            {/*                />*/}
            {/*                <InputLabel id="select-label-sex">Sex</InputLabel>*/}
            {/*                <Select*/}
            {/*                    labelId="select-label-sex"*/}
            {/*                    id="select-sex"*/}
            {/*                    value={sportsman_sex}*/}
            {/*                    label="Sex"*/}
            {/*                    onChange={this.handleSexChange.bind(this)}*/}
            {/*                >*/}
            {/*                    <MenuItem value={1}>Male</MenuItem>*/}
            {/*                    <MenuItem value={0}>Female</MenuItem>*/}
            {/*                </Select>*/}
            {/*            </Grid>*/}
            {/*            <Grid item xs={4}>*/}
            {/*                <Autocomplete*/}
            {/*                    options={sportsman_sex ? weight_list_male : weight_list_female}*/}
            {/*                    getOptionLabel={option => option.label ?? option}*/}
            {/*                    autoComplete*/}
            {/*                    includeInputInList*/}
            {/*                    onChange={this.handleWeightChange.bind(this)}*/}
            {/*                    renderInput={params => (*/}
            {/*                        <TextField {...params} label="Weight" margin="normal" fullWidth/>)}*/}
            {/*                    value={sportsman_weight}*/}
            {/*                />*/}
            {/*                <InputLabel id="demo-simple-select-label">Weight</InputLabel>*/}
            {/*                <Select*/}
            {/*                    labelId="demo-simple-select-label"*/}
            {/*                    id="demo-simple-select"*/}
            {/*                    value={sportsman_weight_input}*/}
            {/*                    label="Weight"*/}
            {/*                    onChange={this.handleWeightInputChange.bind(this)}*/}
            {/*                >*/}
            {/*                    <MenuItem value={0}>Zero</MenuItem>*/}
            {/*                    <MenuItem value={10}>Ten</MenuItem>*/}
            {/*                    <MenuItem value={20}>Twenty</MenuItem>*/}
            {/*                    <MenuItem value={30}>Thirty</MenuItem>*/}
            {/*                </Select>*/}
            {/*                <TextField*/}
            {/*                    label="Weight: "*/}
            {/*                    placeholder=""*/}
            {/*                    margin="normal" fullWidth*/}
            {/*                    InputLabelProps={{*/}
            {/*                        shrink: true,*/}
            {/*                    }}*/}
            {/*                    value={sportsman_name}*/}
            {/*                    onChange={this.handleChange.bind(this)}*/}
            {/*                    name="sportsman_name"*/}
            {/*                    type="text"*/}
            {/*                />*/}
            {/*                <TextField*/}
            {/*                    label="Sherdog Link: "*/}
            {/*                    placeholder=""*/}
            {/*                    margin="normal" fullWidth*/}
            {/*                    InputLabelProps={{*/}
            {/*                        shrink: true,*/}
            {/*                    }}*/}
            {/*                    value={sportsman_sherdog_link}*/}
            {/*                    onChange={this.handleChange.bind(this)}*/}
            {/*                    name="sportsman_sherdog_link"*/}
            {/*                    type="text"*/}
            {/*                />*/}
            {/*                <TextField*/}
            {/*                    label="Sherdog Link: "*/}
            {/*                    placeholder=""*/}
            {/*                    margin="normal" fullWidth*/}
            {/*                    InputLabelProps={{*/}
            {/*                        shrink: true,*/}
            {/*                    }}*/}
            {/*                    value={sportsman_tapology_link}*/}
            {/*                    onChange={this.handleChange.bind(this)}*/}
            {/*                    name="sportsman_tapology_link"*/}
            {/*                    type="text"*/}
            {/*                />*/}
            {/*            </Grid>*/}
            {/*            <Grid item xs={4}>*/}
            {/*                <button className="btn btn-primary" onClick={this.addNewSportsman}>Add*/}
            {/*                </button>*/}
            {/*            </Grid>*/}
            {/*        </Grid>*/}
            {/*    </CardContent>*/}
            {/*</Card>*/}

            <div><br /></div>

            <Card>
                <CardHeader title="Sportsman Actions" className="card-header"></CardHeader>
                <CardContent>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Divider>
                                <Chip label="Add Sportsman" />
                            </Divider>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                label="Name: "
                                placeholder=""
                                margin="normal" fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={sportsman_name}
                                onChange={this.handleChange.bind(this)}
                                name="sportsman_name"
                                type="text"
                            />
                            <TextField
                                label="Surname: "
                                placeholder=""
                                margin="normal" fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={sportsman_surname}
                                onChange={this.handleChange.bind(this)}
                                name="sportsman_surname"
                                type="text"
                            />
                            <InputLabel id="select-label-sex">Sex</InputLabel>
                            <Select
                                labelId="select-label-sex"
                                id="select-sex"
                                value={sportsman_sex}
                                label="Sex"
                                onChange={this.handleSexChange.bind(this)}
                            >
                                <MenuItem value={1}>Male</MenuItem>
                                <MenuItem value={0}>Female</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                options={sportsman_sex ? weight_list_male : weight_list_female}
                                getOptionLabel={option => option.label ?? option}
                                autoComplete
                                includeInputInList
                                onChange={this.handleWeightChange.bind(this)}
                                renderInput={params => (
                                    <TextField {...params} label="Weight" margin="normal" fullWidth />)}
                                value={sportsman_weight}
                            />
                            <TextField
                                label="Sherdog Link: "
                                placeholder=""
                                margin="normal" fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={sportsman_sherdog_link}
                                onChange={this.handleChange.bind(this)}
                                name="sportsman_sherdog_link"
                                type="text"
                            />
                            <TextField
                                label="Tapology Link: "
                                placeholder=""
                                margin="normal" fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={sportsman_tapology_link}
                                onChange={this.handleChange.bind(this)}
                                name="sportsman_tapology_link"
                                type="text"
                            />
                        </Grid>
                    </Grid>

                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {/*<button className="btn btn-primary" onClick={this.addNewSportsman}>Add*/}
                            {/*</button>*/}
                            <Button variant="contained" style={{ width: "100%" }}
                                onClick={this.addNewSportsman}>Add</Button>
                        </Grid>
                    </Grid>
                    <br />

                    <Grid item xs={12}>
                        <Divider>
                            <Chip label="Update Sportsman" />
                        </Divider>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Autocomplete
                                options={list_all_sportsman}
                                getOptionLabel={option => option.SportsmanName + " " + option.SportsmanSurname + " " + option.SportsmanWeight + " kg " ?? option}
                                autoComplete
                                includeInputInList
                                onChange={this.handleSportsmanChange.bind(this)}
                                renderInput={params => (
                                    <TextField {...params} label="Select Sportsman to Edit" margin="normal"
                                        fullWidth />)}
                                value={selected_sportsman}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Name: "
                                placeholder=""
                                margin="normal" fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={sportsman_name}
                                onChange={this.handleChange.bind(this)}
                                name="sportsman_name"
                                type="text"
                            />
                            <TextField
                                label="Surname: "
                                placeholder=""
                                margin="normal" fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={sportsman_surname}
                                onChange={this.handleChange.bind(this)}
                                name="sportsman_surname"
                                type="text"
                            />
                            <InputLabel id="select-label-sex">Sex</InputLabel>
                            <Select
                                labelId="select-label-sex"
                                id="select-sex"
                                value={sportsman_sex}
                                label="Sex"
                                onChange={this.handleSexChange.bind(this)}
                            >
                                <MenuItem value={1}>Male</MenuItem>
                                <MenuItem value={0}>Female</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                options={sportsman_sex ? weight_list_male : weight_list_female}
                                getOptionLabel={option => option.label ?? option}
                                autoComplete
                                includeInputInList
                                onChange={this.handleWeightChange.bind(this)}
                                renderInput={params => (
                                    <TextField {...params} label="Weight" margin="normal" fullWidth />)}
                                value={sportsman_weight}
                            />

                            <TextField
                                label="Sherdog Link: "
                                placeholder=""
                                margin="normal" fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={sportsman_sherdog_link}
                                onChange={this.handleChange.bind(this)}
                                name="sportsman_sherdog_link"
                                type="text"
                            />
                            <TextField
                                label="Tapology Link: "
                                placeholder=""
                                margin="normal" fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={sportsman_tapology_link}
                                onChange={this.handleChange.bind(this)}
                                name="sportsman_tapology_link"
                                type="text"
                            />
                        </Grid>
                    </Grid>

                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Button variant="contained" style={{ width: "100%" }}
                                onClick={this.updateSportsman}>Update</Button>
                        </Grid>
                    </Grid>
                    <br />

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Divider>
                                <Chip label="Add / Remove Video Link" />
                            </Divider>
                        </Grid>
                        <Grid item xs={10}>
                            {/*<TextField*/}
                            {/*    label="Sportsman: "*/}
                            {/*    placeholder=""*/}
                            {/*    margin="normal" fullWidth*/}
                            {/*    InputLabelProps={{*/}
                            {/*        shrink: true,*/}
                            {/*    }}*/}
                            {/*    value={event_name}*/}
                            {/*    onChange={this.handleChange.bind(this)}*/}
                            {/*    name="event_name"*/}
                            {/*    type="text"*/}
                            {/*/>*/}
                            <TextField
                                label="Video Link: "
                                placeholder=""
                                margin="normal" fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={video_link_address}
                                onChange={this.handleChange.bind(this)}
                                name="video_link_address"
                                type="text"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            {/*<button className="btn btn-primary" onClick={this.addNewSportsmanVideoLink}>Add*/}
                            {/*</button>*/}
                            <Button variant="contained" style={{ width: "100%" }}
                                onClick={this.addNewSportsmanVideoLink}>Add</Button>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">Video Link</TableCell>
                                            <TableCell align="left">&nbsp;</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {sportsman_video_links.map((row) => (<TableRow
                                            key={row.idSportsmanVideoLink}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="left">{row.VideoLink}</TableCell>
                                            <TableCell align="right">
                                                <button className="btn btn-primary"
                                                    onClick={() => {
                                                        this.removeSportsmanVideoLink(row.idSportsmanVideoLink)
                                                    }}>Remove
                                                </button>
                                            </TableCell>
                                        </TableRow>))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                    {/*<Grid item xs={4}>*/}
                    {/*    <button className="btn btn-primary" onClick={this.addNewSportsman}>Add*/}
                    {/*    </button>*/}
                    {/*</Grid>*/}
                </CardContent>
            </Card>

            <div><br /></div>

            <Card>
                <CardHeader title="Event Actions" className="card-header"></CardHeader>
                <CardContent>


                    <Grid item xs={12}>
                        <Divider>
                            <Chip label="Add Event" />
                        </Divider>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                label="Name: "
                                placeholder=""
                                margin="normal" fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={event_name}
                                onChange={this.handleChange.bind(this)}
                                name="event_name"
                                type="text"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Date: "
                                placeholder=""
                                margin="normal" fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={event_date}
                                onChange={this.handleChange.bind(this)}
                                name="event_date"
                                type="text"
                            />
                        </Grid>
                    </Grid>

                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {/*<button className="btn btn-primary" onClick={this.addNewEvent}>Add*/}
                            {/*</button>*/}
                            <Button variant="contained" style={{ width: "100%" }}
                                onClick={this.addNewEvent}>Add</Button>
                        </Grid>
                    </Grid>
                    <br />

                    <Grid item xs={12}>
                        <Divider>
                            <Chip label="Update Event" />
                        </Divider>
                    </Grid>

                    <Grid item xs={12}>
                        <Autocomplete
                            options={list_all_events}
                            getOptionLabel={option => option.EventName + " " + option.EventDate ?? option}
                            autoComplete
                            includeInputInList
                            onChange={this.handleEventChange.bind(this)}
                            renderInput={params => (
                                <TextField {...params} label="Select Event" margin="normal" fullWidth />)}
                            value={selected_event}
                        />
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                label="Name: "
                                placeholder=""
                                margin="normal" fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={event_name}
                                onChange={this.handleChange.bind(this)}
                                name="event_name"
                                type="text"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Date: "
                                placeholder=""
                                margin="normal" fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={event_date}
                                onChange={this.handleChange.bind(this)}
                                name="event_date"
                                type="text"
                            />
                        </Grid>
                    </Grid>

                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {/*<button className="btn btn-primary" onClick={this.addNewEvent}>Add*/}
                            {/*</button>*/}
                            <Button variant="contained" style={{ width: "100%" }}
                                onClick={this.updateEvent}>Update</Button>
                        </Grid>
                    </Grid>
                    <br />

                </CardContent>
            </Card>

            {/*<Card>*/}
            {/*    <CardHeader title="Add Sportsman Video Link" className="card-header"></CardHeader>*/}
            {/*    <CardContent>*/}
            {/*        <Grid container spacing={2}>*/}
            {/*            <Grid item xs={4}>*/}
            {/*                <TextField*/}
            {/*                    label="Sportsman: "*/}
            {/*                    placeholder=""*/}
            {/*                    margin="normal" fullWidth*/}
            {/*                    InputLabelProps={{*/}
            {/*                        shrink: true,*/}
            {/*                    }}*/}
            {/*                    value={event_name}*/}
            {/*                    onChange={this.handleChange.bind(this)}*/}
            {/*                    name="event_name"*/}
            {/*                    type="text"*/}
            {/*                />*/}
            {/*                <TextField*/}
            {/*                    label="Video Link: "*/}
            {/*                    placeholder=""*/}
            {/*                    margin="normal" fullWidth*/}
            {/*                    InputLabelProps={{*/}
            {/*                        shrink: true,*/}
            {/*                    }}*/}
            {/*                    value={event_name}*/}
            {/*                    onChange={this.handleChange.bind(this)}*/}
            {/*                    name="event_name"*/}
            {/*                    type="text"*/}
            {/*                />*/}
            {/*            </Grid>*/}
            {/*            <Grid item xs={4}>*/}
            {/*                <button className="btn btn-primary" onClick={this.addNewSportsmanVideoLink}>Add*/}
            {/*                </button>*/}
            {/*            </Grid>*/}
            {/*        </Grid>*/}
            {/*    </CardContent>*/}
            {/*</Card>*/}

            <div><br /></div>

            <Card>
                <CardHeader title="Match Make" className="card-header"></CardHeader>
                <CardContent>

                    <Grid item xs={12}>
                        <Divider>
                            <Chip label="Add New Match" />
                        </Divider>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Autocomplete
                                options={list_all_events}
                                getOptionLabel={option => option.EventName + " " + option.EventDate ?? option}
                                autoComplete
                                includeInputInList
                                onChange={this.handleEventChange.bind(this)}
                                renderInput={params => (
                                    <TextField {...params} label="Select Event" margin="normal" fullWidth />)}
                                value={selected_event}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            {/*<TextField*/}
                            {/*    label="Sportsman Left: "*/}
                            {/*    placeholder=""*/}
                            {/*    margin="normal" fullWidth*/}
                            {/*    InputLabelProps={{*/}
                            {/*        shrink: true,*/}
                            {/*    }}*/}
                            {/*    value={sportsman_name}*/}
                            {/*    onChange={this.handleChange.bind(this)}*/}
                            {/*    name="sportsman_name"*/}
                            {/*    type="text"*/}
                            {/*/>*/}
                            <Autocomplete
                                options={list_all_sportsman}
                                getOptionLabel={option => option.SportsmanName + " " + option.SportsmanSurname + " " + option.SportsmanWeight + " kg " ?? option}
                                autoComplete
                                includeInputInList
                                onChange={this.handleSportsmanLeftChange.bind(this)}
                                renderInput={params => (
                                    <TextField {...params} label="Select Sportsman Left" margin="normal"
                                        fullWidth />)}
                                value={sportsman_left}
                            />
                            {/*<TextField*/}
                            {/*    label="Sportsman Right: "*/}
                            {/*    placeholder=""*/}
                            {/*    margin="normal" fullWidth*/}
                            {/*    InputLabelProps={{*/}
                            {/*        shrink: true,*/}
                            {/*    }}*/}
                            {/*    value={sportsman_name}*/}
                            {/*    onChange={this.handleChange.bind(this)}*/}
                            {/*    name="sportsman_name"*/}
                            {/*    type="text"*/}
                            {/*/>*/}
                            <Autocomplete
                                options={list_all_sportsman}
                                getOptionLabel={option => option.SportsmanName + " " + option.SportsmanSurname + " " + option.SportsmanWeight + " kg " ?? option}
                                autoComplete
                                includeInputInList
                                onChange={this.handleSportsmanRightChange.bind(this)}
                                renderInput={params => (
                                    <TextField {...params} label="Select Sportsman Right" margin="normal"
                                        fullWidth />)}
                                value={sportsman_right}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Bout Kg: "
                                placeholder=""
                                margin="normal" fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={bout_kg}
                                onChange={this.handleChange.bind(this)}
                                name="bout_kg"
                                type="text"
                            />
                            {/*<TextField*/}
                            {/*    label="Status: "*/}
                            {/*    placeholder=""*/}
                            {/*    margin="normal" fullWidth*/}
                            {/*    InputLabelProps={{*/}
                            {/*        shrink: true,*/}
                            {/*    }}*/}
                            {/*    value={sportsman_name}*/}
                            {/*    onChange={this.handleChange.bind(this)}*/}
                            {/*    name="sportsman_name"*/}
                            {/*    type="text"*/}
                            {/*/>*/}
                        </Grid>

                    </Grid>

                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {/*<button className="btn btn-primary" onClick={this.doMatchMake}>Add*/}
                            {/*</button>*/}
                            <Button variant="contained" style={{ width: "100%" }}
                                onClick={this.addNewBoutMatch}>Add</Button>
                        </Grid>
                    </Grid>
                    <br />

                    <Grid item xs={12}>
                        <Divider>
                            <Chip label="Update Match" />
                        </Divider>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Autocomplete
                                options={get_bouts_for_an_event}
                                getOptionLabel={option => "\"" + option.EventName + "\"" + " " + option.SportsmanNameLeft + " " + option.SportsmanSurnameLeft + " " + option.SportsmanWeightLeft + " kg " + " -VS- " + option.SportsmanNameRight + " " + option.SportsmanSurnameRight + " " + option.SportsmanWeightRight + " kg " ?? option}
                                autoComplete
                                includeInputInList
                                onChange={this.handleBoutChange.bind(this)}
                                renderInput={params => (
                                    <TextField {...params} label="Select Bout" margin="normal" fullWidth />)}
                                value={selected_bout}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            {/*<TextField*/}
                            {/*    label="Sportsman Left: "*/}
                            {/*    placeholder=""*/}
                            {/*    margin="normal" fullWidth*/}
                            {/*    InputLabelProps={{*/}
                            {/*        shrink: true,*/}
                            {/*    }}*/}
                            {/*    value={sportsman_name}*/}
                            {/*    onChange={this.handleChange.bind(this)}*/}
                            {/*    name="sportsman_name"*/}
                            {/*    type="text"*/}
                            {/*/>*/}
                            <Autocomplete
                                options={list_all_sportsman}
                                getOptionLabel={option => option.SportsmanName + " " + option.SportsmanSurname + " " + option.SportsmanWeight + " kg " ?? option}
                                autoComplete
                                includeInputInList
                                onChange={this.handleSportsmanLeftChange.bind(this)}
                                renderInput={params => (
                                    <TextField {...params} label="Select Sportsman Left" margin="normal"
                                        fullWidth />)}
                                value={sportsman_left}
                            />
                            {/*<TextField*/}
                            {/*    label="Sportsman Right: "*/}
                            {/*    placeholder=""*/}
                            {/*    margin="normal" fullWidth*/}
                            {/*    InputLabelProps={{*/}
                            {/*        shrink: true,*/}
                            {/*    }}*/}
                            {/*    value={sportsman_name}*/}
                            {/*    onChange={this.handleChange.bind(this)}*/}
                            {/*    name="sportsman_name"*/}
                            {/*    type="text"*/}
                            {/*/>*/}
                            <Autocomplete
                                options={list_all_sportsman}
                                getOptionLabel={option => option.SportsmanName + " " + option.SportsmanSurname + " " + option.SportsmanWeight + " kg " ?? option}
                                autoComplete
                                includeInputInList
                                onChange={this.handleSportsmanRightChange.bind(this)}
                                renderInput={params => (
                                    <TextField {...params} label="Select Sportsman Right" margin="normal"
                                        fullWidth />)}
                                value={sportsman_right}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Bout Kg: "
                                placeholder=""
                                margin="normal" fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={bout_kg}
                                onChange={this.handleChange.bind(this)}
                                name="bout_kg"
                                type="text"
                            />
                            {/*<TextField*/}
                            {/*    label="Status: "*/}
                            {/*    placeholder=""*/}
                            {/*    margin="normal" fullWidth*/}
                            {/*    InputLabelProps={{*/}
                            {/*        shrink: true,*/}
                            {/*    }}*/}
                            {/*    value={sportsman_name}*/}
                            {/*    onChange={this.handleChange.bind(this)}*/}
                            {/*    name="sportsman_name"*/}
                            {/*    type="text"*/}
                            {/*/>*/}
                            <InputLabel id="select-label-match-making-status">Status</InputLabel>
                            <Select
                                labelId="select-label-match-making-status"
                                id="select-match-making-status"
                                value={matchmaking_status}
                                label="Status"
                                onChange={this.handleMatchMakingStatusChange.bind(this)}
                            >
                                <MenuItem value={1}>Confirmed</MenuItem>
                                <MenuItem value={0}>Unconfirmed</MenuItem>
                            </Select>
                        </Grid>

                    </Grid>

                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {/*<button className="btn btn-primary" onClick={this.doMatchMake}>Add*/}
                            {/*</button>*/}
                            <Button variant="contained" style={{ width: "100%" }}
                                onClick={this.updateBoutMatch}>Update</Button>
                        </Grid>
                    </Grid>
                    <br />

                    {/*<Grid container spacing={2}>*/}
                    {/*    <Grid item xs={12}>*/}
                    {/*        <TableContainer component={Paper}>*/}
                    {/*            <Table sx={{minWidth: 650}} aria-label="simple table">*/}
                    {/*                <TableHead>*/}
                    {/*                    <TableRow>*/}
                    {/*                        <TableCell align="left">Video Link</TableCell>*/}
                    {/*                        <TableCell align="left">&nbsp;</TableCell>*/}
                    {/*                    </TableRow>*/}
                    {/*                </TableHead>*/}
                    {/*                <TableBody>*/}
                    {/*                    {sportsman_video_links.map((row) => (<TableRow*/}
                    {/*                        key={row.idSportsmanVideoLink}*/}
                    {/*                        sx={{'&:last-child td, &:last-child th': {border: 0}}}*/}
                    {/*                    >*/}
                    {/*                        <TableCell align="left">{row.VideoLink}</TableCell>*/}
                    {/*                        <TableCell align="right">*/}
                    {/*                            <button className="btn btn-primary"*/}
                    {/*                                    onClick={() => {*/}
                    {/*                                        this.testTableFunc1(row.idSportsmanVideoLink)*/}
                    {/*                                    }}>Remove*/}
                    {/*                            </button>*/}
                    {/*                        </TableCell>*/}
                    {/*                    </TableRow>))}*/}
                    {/*                </TableBody>*/}
                    {/*            </Table>*/}
                    {/*        </TableContainer>*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}
                </CardContent>
            </Card>

            <div><br /></div>

            <Card>
                <CardHeader title="See All Bouts and Edit" className="card-header"></CardHeader>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Autocomplete
                                options={list_all_events}
                                getOptionLabel={option => option.EventName + " " + option.EventDate ?? option}
                                autoComplete
                                includeInputInList
                                onChange={this.handleEventChange.bind(this)}
                                renderInput={params => (
                                    <TextField {...params} label="Select Event" margin="normal" fullWidth />)}
                                value={selected_event}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">Event</TableCell>
                                            <TableCell align="right">Sportsman Left</TableCell>
                                            <TableCell align="left">&nbsp;</TableCell>
                                            <TableCell align="left">Sportsman Right</TableCell>
                                            <TableCell align="center">Bout Kg</TableCell>
                                            <TableCell align="center">Confirmed</TableCell>
                                            <TableCell align="left">&nbsp;</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {get_bouts_for_an_event.map((row) => (<TableRow
                                            key={row.idMatchMaking}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="left">{row.EventName}</TableCell>
                                            <TableCell
                                                align="right">{row.SportsmanNameLeft + " " + row.SportsmanSurnameLeft + " " + row.SportsmanWeightLeft + " kg"}</TableCell>
                                            <TableCell align="center"> -VS- </TableCell>
                                            <TableCell
                                                align="left">{row.SportsmanNameRight + " " + row.SportsmanSurnameRight + " " + row.SportsmanWeightRight + " kg"}</TableCell>
                                            <TableCell align="center">{row.BoutKilo + " kg"}</TableCell>
                                            <TableCell align="center">{row.MatchingStatus ? "Yes" : "No"}</TableCell>
                                            <TableCell align="right">
                                                <button className="btn btn-primary"
                                                    onClick={() => {
                                                        this.selectBout(row.idMatchMaking)
                                                    }}>
                                                    Select
                                                    {/*{row.MatchingStatus ? "Unconfirm" : "Confirm"}*/}
                                                </button>
                                            </TableCell>
                                        </TableRow>))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>

                    {/*<br/>*/}
                    {/*<Grid container spacing={2}>*/}
                    {/*    <Grid item xs={12}>*/}
                    {/*        /!*<button className="btn btn-primary" onClick={this.doMatchMake}>Add*!/*/}
                    {/*        /!*</button>*!/*/}
                    {/*        <Button variant="contained" style={{width: "100%"}}*/}
                    {/*                onClick={this.doMatchMake}>Update</Button>*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}
                    {/*<br/>*/}

                </CardContent>
            </Card>

            <div><br /></div>

            <Card>
                <CardHeader title="Match Results for Sherdog" className="card-header"></CardHeader>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={10}>
                            <Autocomplete
                                options={list_all_events_mmafed}
                                getOptionLabel={option => option.baslik + " " + option.tarih ?? option}
                                autoComplete
                                includeInputInList
                                onChange={this.handleEventMMAFedChange.bind(this)}
                                renderInput={params => (
                                    <TextField {...params} label="Select Event" margin="normal" fullWidth />)}
                                value={selected_event_mmafed}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant="contained" style={{ width: "100%" }}
                                onClick={this.generateSherdog}>Sherdog</Button>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">Event</TableCell>
                                            <TableCell align="right">Sportsman Left</TableCell>
                                            <TableCell align="left">&nbsp;</TableCell>
                                            <TableCell align="left">Sportsman Right</TableCell>
                                            <TableCell align="center">Bout Kg</TableCell>
                                            <TableCell align="center">Time</TableCell>
                                            <TableCell align="center">Round</TableCell>
                                            <TableCell align="center">Winner</TableCell>
                                            <TableCell align="center">By</TableCell>
                                            <TableCell align="center">Referee</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {event_with_bouts_mmafed.map((row) => (<TableRow
                                            key={row.m_org_karsilasma_id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="left">{row.m_mac_organizasyon_baslik}</TableCell>
                                            <TableCell
                                                align="left">{row.sol_sporcu_sporcu_ad + " " + row.sol_sporcu_lakap + " " + row.sol_sporcu_dogumtarihi + " " + row.sol_sporcu_kilo + " kg " + row.sol_sporcu_boy + " m " + row.sol_sporcu_uyruk_adi + " " + row.sol_sporcu_tartikontrol_kilo_1 + " kg " + row.sol_sporcu_tartikontrol_kilo_2 + " kg"}</TableCell>
                                            <TableCell align="center"> -VS- </TableCell>
                                            <TableCell
                                                align="right">{row.sag_sporcu_sporcu_ad + " " + row.sag_sporcu_lakap + " " + row.sag_sporcu_dogumtarihi + " " + row.sag_sporcu_kilo + " kg " + row.sag_sporcu_boy + " m " + row.sag_sporcu_uyruk_adi + " " + row.sag_sporcu_tartikontrol_kilo_1 + " kg " + row.sag_sporcu_tartikontrol_kilo_2 + " kg"}</TableCell>
                                            <TableCell
                                                align="center">{row.sag_sporcu_tartikontrol_basvurukilo + " kg " + row.sol_sporcu_tartikontrol_basvurukilo + " kg"}</TableCell>
                                            <TableCell
                                                align="center">{row.m_org_karsilasma_time}</TableCell>
                                            <TableCell
                                                align="center">{row.m_org_karsilasma_raunt}</TableCell>
                                            <TableCell
                                                align="center">{row.m_org_karsilasma_sonuc === 1 ? "L" : "R"}</TableCell>
                                            <TableCell
                                                align="center">{row.m_org_karsilasma_teknik_adi}</TableCell>
                                            <TableCell
                                                align="center">{row.m_mac_organizasyon_hakem_orta_ad}</TableCell>
                                        </TableRow>))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>

                    {/*<br/>*/}
                    {/*<Grid container spacing={2}>*/}
                    {/*    <Grid item xs={12}>*/}
                    {/*        /!*<button className="btn btn-primary" onClick={this.doMatchMake}>Add*!/*/}
                    {/*        /!*</button>*!/*/}
                    {/*        <Button variant="contained" style={{width: "100%"}}*/}
                    {/*                onClick={this.doMatchMake}>Update</Button>*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}
                    {/*<br/>*/}

                </CardContent>
            </Card>

            <div><br /></div>


            {/*<Card>*/}
            {/*    <CardHeader title="All Sportsman" className="card-header"></CardHeader>*/}
            {/*    <CardContent>*/}
            {/*        <Grid container spacing={2}>*/}
            {/*            <Grid item xs={12}>*/}
            {/*                <TextField*/}
            {/*                    label="Name: "*/}
            {/*                    placeholder=""*/}
            {/*                    margin="normal" fullWidth*/}
            {/*                    InputLabelProps={{*/}
            {/*                        shrink: true,*/}
            {/*                    }}*/}
            {/*                    value={event_name}*/}
            {/*                    onChange={this.handleChange.bind(this)}*/}
            {/*                    name="event_name"*/}
            {/*                    type="text"*/}
            {/*                />*/}

            {/*                <TableContainer component={Paper}>*/}
            {/*                    <Table sx={{minWidth: 650}} aria-label="simple table">*/}
            {/*                        <TableHead>*/}
            {/*                            <TableRow>*/}
            {/*                                <TableCell>Dessert (100g serving)</TableCell>*/}
            {/*                                <TableCell align="right">Calories</TableCell>*/}
            {/*                                <TableCell align="right">Fat&nbsp;(g)</TableCell>*/}
            {/*                                <TableCell align="right">Carbs&nbsp;(g)</TableCell>*/}
            {/*                                <TableCell align="right">Protein&nbsp;(g)</TableCell>*/}
            {/*                            </TableRow>*/}
            {/*                        </TableHead>*/}
            {/*                        <TableBody>*/}
            {/*                            {rows.map((row) => (<TableRow*/}
            {/*                                key={row.name}*/}
            {/*                                sx={{'&:last-child td, &:last-child th': {border: 0}}}*/}
            {/*                            >*/}
            {/*                                <TableCell component="th" scope="row">*/}
            {/*                                    {row.name}*/}
            {/*                                </TableCell>*/}
            {/*                                <TableCell align="right">{row.calories}</TableCell>*/}
            {/*                                <TableCell align="right">{row.fat}</TableCell>*/}
            {/*                                <TableCell align="right">{row.carbs}</TableCell>*/}
            {/*                                <TableCell align="right">{row.protein}</TableCell>*/}
            {/*                                <TableCell align="right">*/}
            {/*                                    <button className="btn btn-primary"*/}
            {/*                                            onClick={() => {*/}
            {/*                                                this.testTableFunc1(row.id)*/}
            {/*                                            }}>Remove*/}
            {/*                                    </button>*/}
            {/*                                </TableCell>*/}
            {/*                            </TableRow>))}*/}
            {/*                        </TableBody>*/}
            {/*                    </Table>*/}
            {/*                </TableContainer>*/}
            {/*            </Grid>*/}
            {/*        </Grid>*/}
            {/*    </CardContent>*/}
            {/*</Card>*/}

            {/*<Card>*/}
            {/*    <CardHeader title="All Sportsman" className="card-header"></CardHeader>*/}
            {/*    <CardContent>*/}
            {/*        <Grid container spacing={2}>*/}
            {/*            <Grid item xs={12}>*/}
            {/*                <TableContainer component={Paper}>*/}
            {/*                    <Table sx={{minWidth: 650}} aria-label="simple table">*/}
            {/*                        <TableHead>*/}
            {/*                            <TableRow>*/}
            {/*                                <TableCell align="left">Video Link</TableCell>*/}
            {/*                                <TableCell align="left">&nbsp;</TableCell>*/}
            {/*                            </TableRow>*/}
            {/*                        </TableHead>*/}
            {/*                        <TableBody>*/}
            {/*                            {sportsman_video_links.map((row) => (<TableRow*/}
            {/*                                key={row.idSportsmanVideoLink}*/}
            {/*                                sx={{'&:last-child td, &:last-child th': {border: 0}}}*/}
            {/*                            >*/}
            {/*                                <TableCell align="left">{row.VideoLink}</TableCell>*/}
            {/*                                <TableCell align="right">*/}
            {/*                                    <button className="btn btn-primary"*/}
            {/*                                            onClick={() => {*/}
            {/*                                                this.testTableFunc1(row.idSportsmanVideoLink)*/}
            {/*                                            }}>Remove*/}
            {/*                                    </button>*/}
            {/*                                </TableCell>*/}
            {/*                            </TableRow>))}*/}
            {/*                        </TableBody>*/}
            {/*                    </Table>*/}
            {/*                </TableContainer>*/}
            {/*            </Grid>*/}
            {/*        </Grid>*/}
            {/*    </CardContent>*/}
            {/*</Card>*/}

        </div>);
    }
}


